import Link from 'next/link'
import React from 'react'

const AcceptTermsAndPrivacy = () => {
  return (
    <div style={{ margin: '0.5em 0' }}>
      By creating an account or signing in you agree to our <Link href="/terms">Terms and Conditions</Link>
      {' and '}
      <Link href="/privacy">Privacy Policy</Link>
    </div>
  )
}

export default AcceptTermsAndPrivacy
