// External
import { Formik, Form, Field, ErrorMessage, useField } from 'formik'

// Internal

export const TextInput = ({ label, type = 'text', ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <label className="label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <input className="text-input" aria-label type={type} {...field} {...props} />
      {meta.touched && meta.error ? <div className="red margin-bot-05">{meta.error}</div> : null}
    </>
  )
}
