import _ from 'lodash'

import SSOButton from '@components/sso/SSOButton'
import { AuthUrl, AuthUrlType } from '@interfaces/graphql'

interface SSOAuthProps {
  authUrls: AuthUrl[]
  onClick: (url: AuthUrl) => void

  // TODO: Maybe just use context for this...
  noLogo?: boolean
}

const SSOAuth = ({ authUrls, onClick, noLogo = false }: SSOAuthProps) => {
  return (
    <div>
      {authUrls
        .slice()
        .sort((a, b) => a?.type?.localeCompare(b?.type || '') || 0)
        .map((authUrl) => (
          <SSOButton
            noLogo={noLogo}
            key={authUrl.type}
            onClick={() => {
              onClick(authUrl)
            }}
            connectionType={authUrl.type || AuthUrlType.GoogleOAuth}
          />
        ))}
    </div>
  )
}

export default SSOAuth
