import Image from '@components/image/Image'
import { AuthUrlType } from '@interfaces/graphql'

import styles from './SSO.module.scss'

interface SSOButtonProps {
  onClick: () => void
  connectionType: AuthUrlType
  noLogo?: boolean
}

type ConnectionConfig = {
  imageSrc: string
  alt: string
  name: string
}

const connectionAssets: { [key in AuthUrlType]?: ConnectionConfig } = {
  [AuthUrlType.GoogleOAuth]: {
    imageSrc: '/images/logo/google-logo.svg',
    alt: 'Google Logo',
    name: 'Google',
  },
  [AuthUrlType.GoogleSaml]: {
    imageSrc: '/images/logo/google-logo.svg',
    alt: 'Google Logo',
    name: 'Google',
  },
  [AuthUrlType.AzureSaml]: {
    imageSrc: '/images/logo/microsoft-logo.png',
    alt: 'Microsoft Logo',
    name: 'Microsoft',
  },
  [AuthUrlType.Adfssaml]: {
    imageSrc: '/images/logo/microsoft-logo.png',
    alt: 'Microsoft Logo',
    name: 'Microsoft',
  },
  [AuthUrlType.MicrosoftOAuth]: {
    imageSrc: '/images/logo/microsoft-logo.png',
    alt: 'Microsoft Logo',
    name: 'Microsoft',
  },
  [AuthUrlType.OktaSaml]: {
    imageSrc: '/images/logo/okta-logo.png',
    alt: 'Okta Logo',
    name: 'Okta',
  },
}

const SSOButton = ({ onClick, connectionType, noLogo = false }: SSOButtonProps) => {
  let { imageSrc, alt, name } = connectionAssets[connectionType] || {}

  return (
    <div
      className={styles.ssoBtn}
      onClick={() => {
        onClick()
      }}
    >
      {!noLogo && <Image src={imageSrc} alt={alt} customStyles={{ height: '18px', marginRight: '0.5rem' }} />}
      Continue with {name}
    </div>
  )
}

export default SSOButton
