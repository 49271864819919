import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import Button from '@components/button/Button'
import { trackEvent } from '@helpers/analytics/trackEvent'
import {
  GetMeDocument,
  useCheckVerificationMutation,
  useLogoutMutation,
  useSendVerificationMutation,
} from '@interfaces/graphql'
import { setPermissions } from '@redux/reducers/PermissionsSlice'
import { resetUser, setUser } from '@redux/reducers/UserSlice'
import { setWorkspace } from '@redux/reducers/WorkspaceSlice'
import { setUsers } from '@src/redux/reducers/WorkspaceUsersSlice'
import { getMeTypeFromGraphQLUserType } from '@src/partials/app/hooks/useGetMe'
import { getWorkspaceSliceTypeFromGraphQLWorkspaceType } from '@src/partials/app/hooks/useGetWorkspace'

interface MFAProps {
  title?: string
  method?: 'Email' | 'SMS'
  autosend?: boolean
  sendButton?: boolean
  message?: string
  showOptions?: boolean
  onSuccess?: () => void
  embedded?: boolean
}

// HACK! MFA currently only supports Email verification
export const MFA = (props: MFAProps) => {
  const {
    title,
    method = 'Email',
    autosend = false,
    sendButton = false,
    message,
    showOptions = true,
    onSuccess: inputOnSuccess,
    embedded,
  } = props

  const router = useRouter()
  const dispatch = useDispatch()
  const [success, setSuccess] = useState<string>()
  const [error, setError] = useState<string>()

  const [token, setToken] = useState<string>()
  const verificationMethod = useRef(method)
  const [sentToken, setSentToken] = useState<'Sent' | 'Resend'>()

  const onSuccess = inputOnSuccess ?? (() => router.push('/signup/success'))

  useEffect(() => {
    if (method && autosend) {
      handleSendVerification()
    }
  }, [])

  useEffect(() => {
    if (token && token.length == 6) {
      handleCheckVerificationCode(token, verificationMethod.current)
    } else if (error || success) {
      setError(undefined)
      setSuccess(undefined)
    }
  }, [token])

  const [verifyCode] = useCheckVerificationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [GetMeDocument],
    onCompleted: (results) => {
      setSuccess('Verified! Welcome...')

      if (!results.checkVerification) return

      dispatch(setUser(getMeTypeFromGraphQLUserType(results.checkVerification)))

      const perms = results?.checkVerification.role?.permissions || []
      dispatch(setPermissions(perms))

      const workspace = results.checkVerification.workspace
      dispatch(setWorkspace(getWorkspaceSliceTypeFromGraphQLWorkspaceType(workspace)))

      onSuccess()
    },
    onError: (results) => {
      setSentToken('Resend')

      if (results && results.message.includes('Invalid code')) {
        setError('Invalid code.')
      } else if (results.message.includes('Max attempts reached')) {
        setError('Too many attempts. Please wait 10 minutes and try again.')
      } else {
        setError('Something went wrong.')
      }

      trackEvent('Sign Up Error', JSON.stringify(results))
    },
  })

  const [sendVerification] = useSendVerificationMutation({
    onCompleted: (results) => {
      setSentToken('Sent')

      setTimeout(() => {
        setSentToken('Resend')
      }, 15000)
    },
    onError: (results) => {
      setError('Uh oh. Something went wrong, there. Please refresh the page and try again.')
    },
  })

  const handleCheckVerificationCode = (code, method) => {
    verifyCode({ variables: { method: method, code: code } })
  }

  const handleSendVerification = async () => {
    setError(undefined)
    await sendVerification({ variables: { method: 'Email' } })
  }

  const [logout] = useLogoutMutation()

  const handleLogout = async () => {
    await logout()

    dispatch(resetUser())
    dispatch(setPermissions(false))
    dispatch(setUsers(false))
    dispatch(setWorkspace({}))
    if (embedded) {
      router.push('/login?embedded=true')
    } else {
      router.push('/')
    }
  }

  return (
    <div className="flexBoxCol" style={{ marginTop: '4em', padding: '2em' }}>
      <Head>
        <title>NextStage</title>
      </Head>
      <h2 style={{ margin: '1rem auto' }}>{title || 'Multi-Factor Authentication'}</h2>

      <div className="form">
        {showOptions && (
          <div>
            <label>Choose your authentication method</label>
            <div className="flexBoxRow margin-top-05">
              {/* {methods.map((method) => ( */}
              <Button
                key={'Email'}
                btnType={'primary'}
                onClick={() => {
                  // verificationMethod.current = method
                  handleSendVerification()
                }}
              >
                {method}
              </Button>
              {/* ))} */}
            </div>
          </div>
        )}

        {message && <span>{message}</span>}
        {sendButton && (
          <Button
            className="margin-top-1"
            onClick={() => {
              handleSendVerification()
            }}
          >
            Send {verificationMethod.current}
          </Button>
        )}

        {verificationMethod.current && (
          <div className="flexBoxCol margin-top-1">
            <label>Enter your verification code:</label>
            <div className="flexBoxRow margin-bot-05 ">
              <input
                className="input"
                onChange={(e) => {
                  setToken(e.target.value.trim())
                }}
              />

              {sentToken && (
                <span
                  className={sentToken == 'Sent' ? 'green' : 'link'}
                  style={{ marginTop: '0.5rem', marginLeft: '0.5rem' }}
                  onClick={() => {
                    if (sentToken === 'Resend') {
                      handleSendVerification()
                    }
                  }}
                >
                  {sentToken}
                </span>
              )}
            </div>

            {success && <span className="green">{success}</span>}
            {error && <span className="red">{error}</span>}
          </div>
        )}
        <Button customStyles={{ width: '10em' }} onClick={() => handleLogout()}>
          Log out
        </Button>
      </div>
    </div>
  )
}
